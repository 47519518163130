<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>园区重点企业用工情况</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
            <div class="search">
                <div class="searchleft">
                    <el-form :inline="true"  class="demo-form-inline">
                        <el-form-item label="企业名称">
                            <el-input v-model="searchform.name" placeholder="请输入内容"></el-input>
                        </el-form-item>
                        <el-form-item label="所属地区">
                            <el-input v-model="searchform.area" placeholder="请输入内容"></el-input>
                        </el-form-item>
                        <el-form-item label="月份">
                            <el-date-picker
                            v-model="searchform.createStartTime"
                            type="month"
                            value-format="yyyy-MM"
                            placeholder="选择月">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" @click="onSubmit"  >查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="searchright"><el-button type="primary" icon="el-icon-circle-plus-outline"  @click="openadd">添加</el-button></div>
            </div>
            <div class="list" v-loading="loading">
                <el-table
                :data="list"
                :header-cell-style="{'font-size':'12px','font-weight':'bold','color':'#555555','padding':'6px 0'}"
                stripe
                border
                style="width: 100%">
                    <el-table-column
                    prop="monthFlag"
                    label="月份">
                    </el-table-column>
                    <el-table-column
                    prop="name"
                    label="企业名称">
                    </el-table-column>
                    <el-table-column
                    prop="area"
                    label="所属地区">
                    </el-table-column>
                    <el-table-column
                    prop="fillingEndNum"
                    label="建档期末员工人数">
                    </el-table-column>
                    <el-table-column
                    prop="surveyEndNum"
                    label="调查期末员工人数">
                    </el-table-column>
                    <el-table-column
                    prop="surveyEndNewNum"
                    label="调查期末新招人数">
                    </el-table-column>
                    <el-table-column
                    prop="surveyEndReduceNum"
                    label="调查期末减员人数">
                    </el-table-column>
                    <el-table-column
                    prop="postChangeNum"
                    label="岗位变化总数">
                    </el-table-column>
                    <el-table-column
                    prop="postChangePercent"
                    label="岗位变化数量占比(%)">
                    </el-table-column>
                    <el-table-column
                    prop="predictNextNewNum"
                    label="预计下月新招员工人数">
                    </el-table-column>
                    <el-table-column
                    prop="predictNextReduceNum"
                    label="预计下月减员人数">
                    </el-table-column>
                    <el-table-column
                    prop="remark"
                    label="备注说明">
                    </el-table-column>
                    <el-table-column
                    prop="mobile"
                    width="180"
                    label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="openedit(scope.row)"  plain>编辑</el-button>
                            <el-button type="warning" @click="deleteitem(scope.row)"  plain>删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <app-page v-if="total!=0" :total="total" :pageSize="pageSize" :current="pageNo" @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
        </div>
        <pop-up :title="status==1?'添加':'编辑'" width="40%" :isshow="showpop" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <el-form ref="form"  label-width="170px">
                    <el-form-item label="企业名称">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="所属地区">
                        <el-input v-model="form.area"></el-input>
                    </el-form-item>
                    <el-form-item label="建档期末员工人数">
                        <el-input-number v-model="form.fillingEndNum" :min="0" :max="100000" ></el-input-number>
                    </el-form-item>
                    <el-form-item label="调查期末员工人数">
                        <el-input-number v-model="form.surveyEndNum" :min="0" :max="100000" ></el-input-number>
                    </el-form-item>
                    <el-form-item label="调查期末新招人数">
                        <el-input-number v-model="form.surveyEndNewNum" :min="0" :max="100000" ></el-input-number>
                    </el-form-item>
                    <el-form-item label="调查期末减员人数">
                        <el-input-number v-model="form.surveyEndReduceNum" :min="0" :max="100000" ></el-input-number>
                    </el-form-item>
                    <el-form-item label="岗位变化总数">
                        <el-input-number v-model="form.postChangeNum" :min="0" :max="100000" ></el-input-number>
                    </el-form-item>
                    <el-form-item label="岗位变化数量占比(%)">
                        <el-input-number v-model="form.postChangePercent" :min="0" :max="100000" ></el-input-number>
                    </el-form-item>
                    <el-form-item label="预计下月新招员工人数">
                        <el-input-number v-model="form.predictNextNewNum" :min="0" :max="100000" ></el-input-number>
                    </el-form-item>
                    <el-form-item label="预计下月减员人数">
                        <el-input-number v-model="form.predictNextReduceNum" :min="0" :max="100000" ></el-input-number>
                    </el-form-item>
                    <el-form-item label="备注说明">
                        <el-input v-model="form.remark"></el-input>
                    </el-form-item>
                    
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
import _api from "@/api/index";
import apiUrl from "@/api/public";
export default {
    data(){
        return{
            searchform:{name:null,area:null,createStartTime:null},
            loading:false,
            status:1,
            total:0,
            pageNo:1,
            pageSize:10,
            showpop:false,
            list:[],
            form:{
                id:null,
                name:null,
                area:null,
                fillingEndNum:null,
                surveyEndNum:null,
                surveyEndNewNum:null,
                surveyEndReduceNum:null,
                postChangeNum:null,
                postChangePercent:null,
                predictNextNewNum:null,
                predictNextReduceNum:null,
                remark:null,
            }
        }
    },
    created(){
        this.getList()
    },
    methods:{
        deleteitem(row){
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                _api.delete(apiUrl.industryuse,row.id).then(res=>{
                    if(res.code==200){
                        this.$message.success("删除成功！")
                        if(((this.total-1)%this.pageSize)<=0){
                            this.pageNo=this.pageNo-1
                        }
                        this.getList()
                    }else{
                        this.$message.error(res.message)
                    }
                })
            }).catch(() => {
                   
            });
        },
        openadd(){
            this.showpop=true
        },
        openedit(row){
            for(let k in this.form){
                this.form[k]=row[k]
            }
            this.status=2
            this.showpop=true
        },
        getclose(){
            this.showpop=false
            this.form={
                id:null,
                name:null,
                area:null,
                fillingEndNum:null,
                surveyEndNum:null,
                surveyEndNewNum:null,
                surveyEndReduceNum:null,
                postChangeNum:null,
                postChangePercent:null,
                predictNextNewNum:null,
                predictNextReduceNum:null,
                remark:null,
            }
        },
        getconfirm(){
            let apitype=this.status==1?'post':'put'
            _api[apitype](apiUrl.industryuse,this.form).then(res=>{
                console.log(res)
                if(res.code==200){
                    this.$message.success("操作成功！")
                    this.getclose()
                    this.getList()
                }else{
                    this.$message.error(res.message)
                }
            })
        },
        getList(){
            this.loading=true
            _api.get(apiUrl.industryuse,{pageNum:this.pageNo,pageSize:this.pageSize,...this.searchform}).then(res=>{
                console.log(res)
                this.loading=false
                if(res.code==200){
                    this.list=res.data.list
                    this.total=res.data.total
                }
            })
        },
        onSubmit(){
            this.pageNo=1
            this.getList()
        },
        handchange(data){//分页Size变化
            this.pageSize=data
            this.pageNo=1
            this.getList()
        },
        currentchange(data){//当前页变化
            console.log(data)
            this.pageNo=data
            this.getList()
        },
    }
}
</script>